import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

type LogoObject = {
    imageUrl: string;
    linkUrl: string;
};

const darkLogos: LogoObject[] = [
    {
        imageUrl: '/images/vahvin-platina.jpg',
        linkUrl: '/files/luotettava-kumppani.pdf'
    },
    {
        imageUrl: '/images/luotettava-kumppani-medium.jpg',
        linkUrl: 'https://zeckit.com/selvitys/FI/1957562-1?lang=fi'
    },
    {
        imageUrl: '/images/asiakastieto.png',
        linkUrl: '/files/asiakastieto-ratingalfa.pdf'
    },
];


const logoStyle = {
    width: '150px',
    height: 'auto',
    margin: '0 32px',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
};

export default function LogoCollection() {
    return (
        <Box id="logoCollection">
            <Grid container justifyContent="center" >
                {darkLogos.map((logo, index) => (
                    <Grid item key={index} sx={{ py: 4 }}>
                        <img
                            src={logo.imageUrl}
                            alt={`Sertifikaatti ${index + 1} logo`}
                            style={logoStyle}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.transform = 'scale(1.08)';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                            }}

                            onClick={() => window.open(logo.linkUrl, '_blank')}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

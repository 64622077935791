import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import MiksiMe from './components/MiksiMe';
import OtaYhteytta from './components/OtaYhteytta';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import {ThemeProvider, createTheme} from "@mui/material";
import getLPTheme from "./getLPTheme";


export default function LandingPage() {
    const LPtheme = createTheme(getLPTheme('light'));

    return (
        <ThemeProvider theme={LPtheme}>
            <CssBaseline/>
            <AppAppBar/>
            <Hero/>
            <MiksiMe/>
            <LogoCollection/>
            <Divider/>
            <OtaYhteytta/>
            <Divider/>
            <Footer/>
        </ThemeProvider>
    );
}
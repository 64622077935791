import * as React from 'react';
import {useRef, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Alert, Button, Checkbox, FormControlLabel, FormGroup, Snackbar, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import emailjs from '@emailjs/browser';

interface SelectedJobTypes {
    [key: string]: boolean;
}

type Severity = "success" | "error" | "info" | "warning" | undefined;


const ContactForm: React.FC = () => {
    const form = useRef<HTMLFormElement>(null);
    const [selectedJobTypes, setSelectedJobTypes] = useState<SelectedJobTypes>({});
    const [notification, setNotification] = useState({open: false, message: '', severity: 'success'});

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedJobTypes({...selectedJobTypes, [event.target.name]: event.target.checked});
    };

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (form.current) {

            const formData = new FormData(form.current);
            const formDataObj: any = {};
            formData.forEach((value, key) => {
                formDataObj[key] = value;
            });

            emailjs.sendForm('service_714kigf', 'template_x2g6te8', form.current, 'SNDtrHj2W7WbANmHH')
                .then(
                    () => {
                        setNotification({open: true, message: 'Viesti lähetetty!', severity: 'success'});
                        if (form.current) {
                            form.current.reset();
                        }
                        setSelectedJobTypes({});
                    },
                    (error) => {
                        setNotification({open: true, message: 'Viestin lähettäminen epäonnistui.', severity: 'error'});
                    },
                );
        }
    };
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setNotification({...notification, open: false});
    };

    return (
        <Container id="otayhteytta" sx={{py: {xs: 8, sm: 16}}}>
            <Grid>
                <Card style={{padding: "0px 5px"}}>
                    <CardContent>
                        <Typography variant="h4" align="center">
                            Ota yhteyttä
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" gutterBottom align="center">
                            Lähetä viesti tai tarjouspyyntösi, niin otamme sinuun yhteyttä.
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" gutterBottom align="center">
                            Voit myös lähettää viestisi sähköpostissa harri.karjunen@handyman.fi osoitteeseen.
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p" gutterBottom align="center">
                            Tähdellä (*) merkityt kentät ovat pakollisia.
                        </Typography>
                        <form ref={form} onSubmit={sendEmail}>
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={6} item>
                                    <TextField placeholder="Lisää etunimi" label="Etunimi" name="etunimi"
                                               variant="outlined"
                                               fullWidth required/>
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField placeholder="Lisää sukunimi" label="Sukunimi" name="sukunimi"
                                               variant="outlined"
                                               fullWidth required/>
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField placeholder="Postinumero ja toimipaikka"
                                               label="Postinumero ja toimipaikka" name="posti"
                                               variant="outlined"
                                               fullWidth/>
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField placeholder="Katusoite" label="Katusoite" name="katuosoite"
                                               variant="outlined"
                                               fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField type="email" placeholder="Lisää sähköposti" label="Sähköposti"
                                               name="email"
                                               variant="outlined"
                                               fullWidth required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField type="number" placeholder="Lisää puhelinnumero" label="Puhelinnumero"
                                               name="puh"
                                               variant="outlined" fullWidth/>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Viesti" name="viesti" multiline rows={4}
                                               placeholder="Kirjoita viestisi tähän"
                                               variant="outlined" fullWidth required/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Huoneistoremontti"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Huoneistoremontti"
                                                value="Huoneistoremontti"
                                            />
                                        }
                                        label="Huoneistoremontti"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Huoneiston_maalaus"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Huoneiston_maalaus"
                                                value="Huoneiston_maalaus"
                                            />
                                        }
                                        label="Huoneiston maalaus"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Vesieristys"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Vesieristys"
                                                value="Vesieristys"
                                            />
                                        }
                                        label="Vesieristystyö"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Kosteusvauriokorjaus"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Kosteusvauriokorjaus"
                                                value="Kosteusvauriokorjaus"
                                            />
                                        }
                                        label="Kosteusvauriokorjaus"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Lattiavalut"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Lattiavalut"
                                                value="Lattiavalut"
                                            />
                                        }
                                        label="Lattiavalut"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Seina_ja_lattiatasoitukset"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Seina_ja_lattiatasoitukset"
                                                value="Seina_ja_lattiatasoitukset"
                                            />
                                        }
                                        label="Seinä- ja lattiatasoitukset"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Kylpyhuoneremontit"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Kylpyhuoneremontit"
                                                value="Kylpyhuoneremontit"
                                            />
                                        }
                                        label="Kylpyhuoneremontit"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Saunaremontit"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Saunaremontit"
                                                value="Saunaremontit"
                                            />
                                        }
                                        label="Saunaremontit"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Keittioremontit"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Keittioremontit"
                                                value="Keittioremontit"
                                            />
                                        }
                                        label="Keittiöremontit"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Laatoitus"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Laatoitus"
                                                value="Laatoitus"
                                            />
                                        }
                                        label="Laatoitus"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedJobTypes["Kalusteasennus"] || false}
                                                onChange={handleCheckboxChange}
                                                name="Kalusteasennus"
                                                value="Kalusteasennus"
                                            />
                                        }
                                        label="Kalusteasennus"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>Lähetä</Button>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={notification.severity as Severity} sx={{width: '100%'}}>
                    {notification.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ContactForm;
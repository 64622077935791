import * as React from 'react';
import { alpha, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : 'linear-gradient(#02294F, #090E10)',
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    pt: { xs: 12, sm: 15, md: 18 }, // Adjust paddingTop responsively
                    pb: { xs: 4 }, // Add extra padding to the bottom for xs breakpoint
                }}
            >
                <Grid container direction={{ xs: 'column', md: 'row' }} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Box
                            id="imagePart1"
                            sx={(theme) => ({
                                //position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                outline: '1px solid',
                                outlineColor: theme.palette.mode === 'light'? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                                boxShadow: theme.palette.mode === 'light'? `0 8px 12px 5px ${alpha('#9CCCFC', 0.2)}` : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                                zIndex: -3,
                                // Adjust parent Box size for sm breakpoint
                                width: { xs: '100%', sm: '100%', md: '100%' },
                                height: { xs: '30vh', sm: '50vh', md: '100%' },
                            })}
                        >
                            <img
                                src="/images/handymanedit.jpg"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    borderRadius: '10px', // Apply border-radius to the image itself
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            id="imagePart2"
                            sx={(theme) => ({
                                //position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                outline: '1px solid',
                                outlineColor: theme.palette.mode === 'light'? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
                                boxShadow: theme.palette.mode === 'light'? `0 8px 12px 5px ${alpha('#9CCCFC', 0.2)}` : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                                zIndex: -3,
                                // Adjust parent Box size for sm breakpoint
                                width: { xs: '100%', sm: '70%', md: '100%' },
                                height: { xs: '30%', sm: '45vh', md: '100%' },
                            })}
                        >
                            <img
                                src="/images/handymanedit2.jpg"
                                alt=""
                                style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    borderRadius: 'inherit', // Apply border-radius to the image itself
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const logoStyle = {
    width: '200px',
    height: 'auto',
};
const hrefStyle = {
    textDecoration: 'none'
}

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://Handyman.fi/">Handyman&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Container id="footer"
            sx={{
                display: 'flex',
                flexDirection: {xs: 'row', sm: 'column'},
                alignItems: 'center',
                gap: {xs: 4, sm: 8},
                py: {xs: 8, sm: 10},
                textAlign: 'left',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{width: {xs: '100%', sm: '60%'}, display: 'flex', alignItems: 'center'}}>
                    <Box>
                        <img
                            src={
                                '/images/logo-no-background.svg'
                            }
                            style={logoStyle}
                            alt="Handymanin logo"
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        paddingTop: {xs: '10px', sm: '0px'},
                        paddingRight: {xs: '0px', sm: '10px'}
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight={600}
                    >
                        Yhteystiedot
                    </Typography>
                    <Typography
                        color="text.secondary"
                    >
                        Työnjohtaja Harri Karjunen
                    </Typography>
                    <a href="tel:+358406873004" style={hrefStyle}>
                        <Typography color="text.secondary">
                            040 687 3004
                        </Typography>
                    </a>
                    <Typography
                        color="text.secondary"
                    >
                        harri.karjunen@handyman.fi
                    </Typography>

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        paddingTop: {xs: '10px', sm: '0px'},
                        paddingRight: {xs: '0px', sm: '10px'}
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        onClick={() => scrollToSection('hero')}
                        sx={{cursor: 'pointer'}}
                    >
                        Yritys
                    </Typography>
                    <Link
                        color="text.secondary"
                        onClick={() => scrollToSection('miksime')}
                        sx={{cursor: 'pointer'}}
                    >
                        Miksi me?
                    </Link>
                    <Link
                        color="text.secondary"
                        onClick={() => scrollToSection('otayhteytta')}
                        sx={{cursor: 'pointer'}}
                    >
                        Ota Yhteyttä
                    </Link>

                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: {xs: 4, sm: 8},
                    width: '100%',
                    borderTop: {xs: '0px', sm: '1px solid'},
                    borderColor: 'divider',
                }}
            >
                <Copyright/>
            </Box>
        </Container>
    );
}
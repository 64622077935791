import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HandymanIcon from '@mui/icons-material/Handyman';
import CottageIcon from '@mui/icons-material/Cottage';

const items = [
    {
        icon: <CottageIcon />,
        title: 'Asiakkaamme',
        description: 'Asiakkaitamme ovat julkishallinnon laitokset, taloyhtiöt, kantakaupungin isännöitsijätoimistot, kiinteistöhuoltoyritykset, arvohuoneistojen osakkeen-omistajat ja haltijat.',
        imageLight: 'url("/images/tausta.jpg")',
        imageDark: '/static/images/templates/templates-images/dash-dark.png',
    },
    {
        icon: <HandymanIcon />,
        title: 'Asiantuntijamme',
        description:
            'Meiltä löytyy ammattitaidostaan ylpeät kirvesmiehet, maalarit, laatoittajat, sertifioidut vesieristäjät, rapparit sekä muurarit.',
        imageLight: 'url("/images/laatoittaja.jpeg")',
        imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
    },
];

export default function MiksiMe() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

    const handleItemClick = (index: number) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[selectedItemIndex];

    return (
        <Container id="miksime" sx={{ py: { xs: 8, sm: 16 } }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography component="h3" variant="h3" color="text.primary" fontWeight={"normal"}>
                            Miksi me?
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            sx={{ mb: { xs: 2, sm: 4 }, py: 1, fontSize: '1.2rem' }}
                        >
                            Me JPM Handyman Oy:llä perehdymme asiakkaamme tarpeisiin huolellisesti ennen työn aloittamista,
                            yhteistyössä kokeneiden kumppaneidemme kanssa. Asiakkaidemme sähkötyöt hoitaa Uudenmaan Sähkömestarit Oy ja
                            putkityöt LVI-Armek Oy. Tämä takaa parhaan mahdollisen lopputuloksen ja aikataulun asiakkaalle.
                        </Typography>
                    </div>
                    <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        {items.map(({ title }, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'primary.light' : '';
                                        }
                                        return selectedItemIndex === index ? 'primary.light' : '';
                                    },
                                    background: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'none' : '';
                                        }
                                        return selectedItemIndex === index ? 'none' : '';
                                    },
                                    backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                                    '& .MuiChip-label': {
                                        color: selectedItemIndex === index ? '#fff' : '',
                                    },
                                }}
                            />
                        ))}
                    </Grid>
                    <Box
                        component={Card}
                        variant="outlined"
                        sx={{
                            display: { xs: 'auto', sm: 'none' },
                            mt: 4,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? items[selectedItemIndex].imageLight
                                        : items[selectedItemIndex].imageDark,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: 280,
                            }}
                        />
                        <Box sx={{ px: 2, pb: 2, py: 2 }}>
                            <Typography color="text.primary" variant="body2" fontWeight="bold"
                                        sx={{ fontSize: '1.1rem' }}
                            >
                                {selectedFeature.title}
                            </Typography>
                            <Typography color="text.primary" variant="body2" sx={{ my: 0.5, fontSize: '1rem' }}>
                                {selectedFeature.description}
                            </Typography>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        useFlexGap
                        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <Card
                                key={index}
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    backgroundColor:
                                        selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : 'grey.200';
                                        }
                                        return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: { md: 'center' },
                                        gap: 2.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: (theme) => {
                                                if (theme.palette.mode === 'light') {
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.300';
                                                }
                                                return selectedItemIndex === index
                                                    ? 'primary.main'
                                                    : 'grey.700';
                                            },
                                        }}
                                    >
                                        {icon}
                                    </Box>
                                    <div>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{ fontSize: '1.1rem' }}
                                        >
                                            {title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            sx={{ my: 0.5, fontSize: '1.05rem' }}
                                        >
                                            {description}
                                        </Typography>

                                    </div>
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            minHeight: 400,
                            minWidth: '100%',
                            display: { xs: 'none', sm: 'flex' },
                            pointerEvents: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                m: 'auto',
                                width: '100%',
                                height: '100%',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundImage: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? items[selectedItemIndex].imageLight
                                        : items[selectedItemIndex].imageDark,
                            }}
                        />
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}